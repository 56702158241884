<template>
	<div>
		<contentHeader title="Supplier" />

		<div class="content-header">
			<div class="container-fluid">
				<div class="row justify-content-center">
					<div class="col">
						<div class="card">
							<div class="card-header row d-flex align-items-center">
		            			<h3 class="card-title col-6">Supplier Obat</h3>
		            			<div class="col-6 text-right">
		            				<router-link to="/tambah-data-supplier"><button class="btn btn-sm btn-info">Tambah</button></router-link>	
		            			</div>
		            		</div>
		            		<div class="card-body">
		            			<table class="table table-bordered table-hover">
								  <thead>
								    <tr>
								      <th scope="col" class="text-sm">NO</th>
								      <th scope="col" class="text-sm">KODE</th>	
								      <th scope="col" class="text-sm">NAMA</th>
								      <th scope="col" class="text-sm">ALAMAT</th>
								      <th scope="col" class="text-sm">TELEPON</th>
								      <th scope="col" class="text-sm">ACTION</th>
								    </tr>
								  </thead>
								  <tbody>
								    <tr v-for="(row, index) in supplier" :key="row.id">
								      <td class="text-sm" scope="row">{{ index+1 }}</td>
								      <td class="text-sm" scope="row">{{ row.kode }}</td>
								      <td class="text-sm" scope="row">{{ row.nama }}</td>
								      <td class="text-sm" scope="row">{{ row.alamat }}</td>
								      <td class="text-sm" scope="row">{{ row.telepon }}</td>
								      <td class="text-sm">
				                        <div class="btn-group">
				                          <button type="button" class="btn btn-sm btn-info text-sm">Proses</button>
				                          <button type="button" class="btn btn-sm btn-info dropdown-toggle dropdown-icon" data-toggle="dropdown" aria-expanded="false">
				                            <span class="sr-only"></span>
				                          </button>
				                          <div class="dropdown-menu" role="menu" style="">
				                          	<router-link :to="`/edit-data-supplier/${row.id}`" class="dropdown-item text-sm">Edit</router-link>
				                            <div class="dropdown-divider"></div>
				                            <button @click="konfirmasiHapus(row.id)" class="dropdown-item text-danger">Hapus</button>
				                          </div>
				                        </div>
				                      </td>
								    </tr>
								  </tbody>
								</table>
								<p v-if="memuat_data" class="text-center mt-2">Memuat Data</p>
								<p v-if="cek_data" class="text-center mt-2">Data Masih Kosong</p>
		            		</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import ContentHeader from '@/components/ContentHeader'

	import { onMounted, ref, computed } from 'vue'
	import axios from 'axios'
	import store from '@/store'
	import Swal from 'sweetalert2'

	export default{
		components: {
			ContentHeader: ContentHeader
		},
		setup() {
    		const user = computed(() => store.getters['auth/user'])
			const supplier = ref([])
			const memuat_data = ref(true)
			const cek_data = ref(false)

			const getSupplier = async () => {
				let { data } = await axios.get(`api/supplier/get/${user.value.cabang_id}`)

				if (data == 'kosong') {
					supplier.value = ''
					memuat_data.value = false
					cek_data.value = true
				} else {
					supplier.value = data
					memuat_data.value = false
					cek_data.value = false
				}
			}

			const konfirmasiHapus = (id) => {
		        Swal.fire({
		          title: 'Hapus Data?',
		          icon: 'warning',
		          showCancelButton: true,
		          confirmButtonText: `Ya`,
		          cancelButtonText: `Batal`,
		        }).then((result) => {
		          /* Read more about isConfirmed, isDenied below */
		          if (result.isConfirmed) {
		            hapusDataSupplier(id)
		          } 
		        })
		      }

		      const hapusDataSupplier = (id) => {
		          axios.delete(`api/supplier/destroy/${id}`)
		          .then((response) => {
		            Swal.fire({
			            title: 'Berhasil!',
			            text: 'Berhasil Menghapus Data',
			            icon: 'success',
			            showConfirmButton: false,
			            timer: 1500
			          })
		            getSupplier()
		            console.log(response.data)
		          })
		          .catch((error) => {
			            Swal.fire({
			            title: 'Gagal!',
			            text: 'Gagal menghapus data',
			            icon: 'error',
			            confirmButtonText: 'Ok'
			          })
		            console.log(error)
		          })

		          getSupplier()
		        }

			onMounted(() => {
				getSupplier()
			})

			return {
				supplier, memuat_data, cek_data, konfirmasiHapus
			}

		}
	}
</script>